<template>
	<div id="sorting" class="container">
		<div class="sorting-save-cont">
			<StatusButton id="sorting-save" :class="{ blinking: changed }" type="primary" alert="modal" @click="save( $event )">{{$t("common.save")}}</StatusButton>
		</div>
		<div class="row">
			<div class="col-md-3">
				<div class="text-center desktop title-legend">{{ $t('common.legend') }}</div>
				<div class="legend desktop">
					<div v-for="( genderText, gender ) in $t('data.genders')">
						<div :class="'square background-' + gender"></div>
						{{ genderText }}
					</div>
				</div>
				<vue-markdown class="text-muted text-sorting d-block desktop">{{$t("administration.sorting")}}</vue-markdown>
			</div>
			<div class="col-md-6 d-flex">
				<Draggable v-model="services" :scroll-sensitivity="100" :force-fallback="true" v-on:start="drag=true" v-on:end="drag=false" @sort="changed = true" class="ui-sortable">
					<div v-for="service in services" :key="service.id" class="ui-sortable-handle" style="">
						<div class="btn prestation-sorting" :data-id="service.id">
							<i class="fas fa-arrows-alt-v"></i>
							<span>{{ service.title }}</span>
							<div class="d-inline-block sorting-tags">
								<div
									v-if="
										(service.female_short && service.female_short[1]) ||
										(service.female_half && service.female_half[1]) ||
										(service.female_long && service.female_long[1])
									"
									class="square background-female"
								>{{ $t('data.genders_short.f') }}</div>
								<div
									v-if="
										(service.male_short && service.male_short[1]) ||
										(service.male_long && service.male_long[1])
									"
									class="square background-male"
								>{{ $t('data.genders_short.m') }}</div>
								<div
									v-if="service.child && service.child[1]"
									class="square background-child"
								>{{ $t('data.genders_short.c') }}</div>
							</div>
						</div>
					</div>
				</Draggable>
				<div class="no-data-hint" v-if="!services.length">
					{{ $t('administration.noprestation' )}}
				</div>
			</div>
			<div class="sorting-save-cont mobile">
				<StatusButton ref="save" id="sorting-save" type="primary" alert="modal" @click="save( $event )">{{$t("common.save")}}</StatusButton>
			</div>
			<div class="col-md-3"></div>
				<vue-markdown class="text-muted text-sorting d-block col-md-3 mobile">{{$t("administration.sorting")}}
			</vue-markdown>
		</div>
		<div id="save-helper" :class="'mobile ' + ( showSaveHelper && changed ? 'visible' : '' )">
			<button class="btn btn-tertiary" @click="$scrollTo( $refs.save.$el, 500 )"></button>
		</div>
		<div class="legend-title text-center mobile">{{ $t('common.legend') }}</div>
		<div class="legend mobile row">
			<div class="col-4" v-for="( genderText, gender ) in $t('data.genders')">
				<div :class="'square background-' + gender"></div>
				{{ genderText }}
			</div>
		</div>
	</div>
</template>

<script>
	import VueMarkdown from 'vue-markdown'
	import Draggable from 'vuedraggable'
	import StatusButton from '../../components/StatusButton.vue'

	export default {
		components: {
			VueMarkdown,
			Draggable,
			StatusButton,
		},
		data() {
			return {
				services: this.$store.getters.getServices()
					.filter( service => service.type >= 0 /* Hide technic */ )
					.sort( ( a, b ) => a.priority - b.priority ),
				changed: false,
				showSaveHelper: false
			}
		},
		methods: {
			save( callback ) {
				if ( !this.changed ) {
					callback( -1 )
					return
				}
				this.$api.services.patch( this.services.map(({ id }, i) => ({ id, priority: i })) ).then( response => {
					callback( true )
					this.changed = false
				}).catch( error => {
					console.log( error )
					callback( false, error.response.data.error )
				})
			},
			scroll() {
				if ( this.$refs.save && this.$refs.save.$el.getBoundingClientRect().bottom > window.innerHeight - document.getElementById( "bottom-mask" ).scrollHeight ) {
					this.showSaveHelper = true
				} else {
					this.showSaveHelper = false
				}
			}
		},
		mounted() {
			this.$store.subscribe( ( mutation, state ) => {
				if ( mutation.type === "updateService" || mutation.type === "deleteService" ) {
					this.services = this.$store.getters.getServices()
						.filter( service => service.type >= 0 /* Hide technic */ )
						.sort( ( a, b ) => a.priority - b.priority );
				}
			})
			window.addEventListener( "scroll", () => this.scroll() )
			this.scroll()
		},
		beforeDestroy() {
			window.removeEventListener( "scroll", () => this.scroll() )
		}
	}
</script>
